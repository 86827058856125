.ripple-container {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ripple {
	position: absolute;
	width: 120%;
	height: 120%;
	background-color: rgba(0, 123, 255, 0.3); /* Light blue */
	border-radius: 50%;
	animation: ripple-pulse 2s infinite;
}

.ripple.delay {
	animation-delay: 0.5s; /* Slight delay for a staggered effect */
}

@keyframes ripple-pulse {
	0% {
		transform: scale(0.8);
		opacity: 1;
	}
	100% {
		transform: scale(1.8);
		opacity: 0;
	}
}
