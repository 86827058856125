.ourdoorMassCoverage {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.ourdoorMassCoverageHeader {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	h5 {
		line-height: 28px;
		margin: 0;
	}
	p {
		margin: 0;
	}
}
.ourdoorMassCoverageHeader_primary {
	display: flex;
	justify-content: space-between;
}

@media only screen and (max-width: 600px) {
	.ourdoorMassCoverage {
		gap: 1rem;
	}

	.ourdoorMassCoverageHeader {
		p {
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.03em;
		}
	}
}

.placements {
	padding: 16px;
	border: 1px solid #ced4da;
	border-radius: 16px;
	margin-top: 24px;
}
