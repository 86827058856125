.openFullMapButton {
	background-color: #6c757d;
	border-radius: 0.5rem;
	padding: 6px 12px;
	color: #fff;

	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}
