.actionsContainer {
	padding: 16px;

	button {
		display: flex;
		align-items: center;
		gap: 4px;
		font-family: Inter;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		border-radius: 12px;
	}
}
