.table {
	width: 100%;
	margin: 0;
	tr {
		border: none !important;
		border-width: 0;
	}
	td {
		border-left: 1px solid #ced4da;
		border-bottom: 1px solid #ced4da;
		padding: 12px 16px;
		height: 1px;
	}
	th {
		border: 1px solid #ced4da;
		height: 48px;

		padding: 12px 16px;

		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		background-color: #f5f5f5;
	}
}
.actionCell {
	&:hover {
		background-color: #f5f5f5;
	}
}
.selectedCell {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}
