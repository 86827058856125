.placementRow {
	border-bottom: 1px solid #ced4da;
	padding: 16px;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5;
	}
}
.selected {
	background-color: #e6f2ff !important;
}
.detailed {
	border: 2px solid #007bff;
	background-color: #f5f5f5 !important;
}
