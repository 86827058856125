.table {
	width: 100%;
	border: 1px solid #ced4da;
	margin: 0;
	th {
		height: 48px;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		background-color: #f5f5f5;
		padding: 12px 16px;
	}
	td {
		height: 1px;
		width: 1px;
		padding: 12px 16px;
	}
}
